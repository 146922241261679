<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage academic calendar</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Academic calendar
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn class="mt-4 btn btn-primary" style="color: #fff" @click="addAcademicCalendar" v-if="checkIsAccessible('academic-calendar', 'list')">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add academic calendar
                                    </v-btn>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="3">
                                    <v-text-field class="form-control" v-model="search.title"
                                        label="Search by title" outlined clearable
                                        v-on:keyup.enter="getAcademicCalendars()" dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select 
                                        class="form-control" 
                                        v-model="search.academic_year_id"
                                        label="Academic year" 
                                        outlined 
                                        clearable
                                        :items="academicYears"
                                        item-text="title"
                                        item-value="id"
                                        v-on:keyup.enter="getAcademicCalendars()" dense>
                                    </v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select 
                                        class="form-control" 
                                        v-model="search.program_id"
                                        label="Program" 
                                        outlined 
                                        clearable
                                        :items="programs"
                                        item-text="title"
                                        item-value="id"
                                        v-on:keyup.enter="getAcademicCalendars()" dense>
                                    </v-select>
                                </v-col>
                                
                                <v-col cols="12" md="3">
                                    <v-btn :loading="loading" @click.prevent="searchAcademicCalendars()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Title</th>
                                        <th class="px-3 wrap-column">Academic year</th>
                                        <th class="px-3 wrap-column">Program</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="academicCalendars.length > 0" v-for="(item, index) of academicCalendars" :key="index">
                                        <td class="px-3 wrap-column">
                                            {{ item.title }}
                                            <div class="my-2">
                                                <span 
                                                    class="badge text-lg "
                                                    :class="item.is_active ? 'badge-success' : 'badge-danger'">
                                                    {{ item.is_active ? 'Active' : 'Inactive' }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.academic_year_title ? item.academic_year_title : 'NA' }}
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.program_title ? item.program_title : 'NA' }}
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="viewCalendar(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-eye"></i>
                                                            </span>
                                                            <span class="navi-text"> View calendar </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-calendar', 'assign-event')">
                                                        <a href="#" class="navi-link" @click="assignEvent(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-plus"></i>
                                                            </span>
                                                            <span class="navi-text"> Assign event </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-calendar', 'edit')">
                                                        <a href="#" class="navi-link" @click="editAcademicCalendar(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-calendar', 'delete')">
                                                        <a href="#" class="navi-link" @click="deleteAcademicCalendar(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="academicCalendars.length == 0">
                                        <td class="text-center  px-3" colspan="5">Data not available</td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="academicCalendars.length > 0" 
                                @input="getAcademicCalendars" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-and-update ref="academic-calendar" @refresh="getAcademicCalendars"></create-and-update>
    </v-app>
</template>

<script>
import AcademicCalendarService from "@/core/services/academic-calendar/AcademicCalendarService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CreateAndUpdate from "./CreateAndUpdate"

const academicCalendar = new AcademicCalendarService();
const academicYear = new AcademicYearService();
const program = new ProgramService();

export default {
    components: {
        CreateAndUpdate
    },
    data() {
        return {
            search: {
                title: '',
                academic_year_id: '',
                program_id: '',
            },
            loading: false,
            academicCalendars: [],
            page: null,
            perPage: null,
            total: null,
            programs: [],
            academicYears: []
        }
    },
    methods: {
        searchAcademicCalendars(){
            this.page = 1;
            this.getAcademicCalendars();
        },  
        getAcademicCalendars() {
            this.loading = true;
            
            academicCalendar
                .paginate(this.search, this.page)
                .then(response => {
                    this.academicCalendars = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addAcademicCalendar() {
            this.$refs["academic-calendar"].showModal();
        },
        editAcademicCalendar(id) {
            this.$refs["academic-calendar"].showModal(id);
        },
        deleteAcademicCalendar(academicCalendarId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        academicCalendar
                        .delete(academicCalendarId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getAcademicCalendars();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        getAcademicYears(){
            academicYear
            .getIncompletedAcademicYears()
            .then((res) => {
                this.academicYears = res.data;
            })
            .catch((err) => {

            });
        },
        getPrograms(){
            program
            .all()
            .then((res) => {
                this.programs = res.data;
            })
            .catch((err) => {

            });
        },
        viewCalendar(academicCalendarId){
            this.$router.push(
                {
                    name: 'view-academic-calendar',
                    params:{
                        academicCalendarId: academicCalendarId
                    }
                }
            );
        },
        assignEvent(academicCalendarId){
            this.$router.push(
                {
                    name: 'assign-calendar-event',
                    params:{
                        academicCalendarId: academicCalendarId
                    }
                }
            );
        }
    },
    mounted() {
        this.getAcademicCalendars();
        this.getPrograms();
        this.getAcademicYears();
    }
}
</script>