<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} academic calendar` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="academicCalendar.academic_year_id"
                                outlined
                                dense
                                :items="academicYears"
                                item-text="title"
                                item-value="id"
                                :error="$v.academicCalendar.academic_year_id.$error"
                            >
                                <template v-slot:label>
                                    Academic year <span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.academicCalendar.academic_year_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <v-autocomplete
                                v-model="academicCalendar.program_id"
                                outlined
                                dense
                                :items="programs"
                                item-text="title"
                                item-value="id"
                                :error="$v.academicCalendar.program_id.$error"
                            >
                                <template v-slot:label>
                                    Program <span class="text-danger"></span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.academicCalendar.program_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="academicCalendar.title"
                                outlined
                                dense
                                :error="$v.academicCalendar.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.academicCalendar.title.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Status
                            </label>
                            <v-switch v-model="academicCalendar.is_active" :label="academicCalendar.is_active ? 'Active' : 'Inactive'"></v-switch>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn 
                                    depressed 
                                    @click="hideModal" 
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                    v-if="checkIsAccessible('academic-calendar', 'create') || checkIsAccessible('academic-calendar', 'edit')"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import AcademicCalendarService from "@/core/services/academic-calendar/AcademicCalendarService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import ProgramService from "@/core/services/level/program/ProgramService";


const academicCalendar = new AcademicCalendarService();
const academicYear = new AcademicYearService();
const program = new ProgramService();

export default {
    validations: {
        academicCalendar: {
            academic_year_id: { required },
            title: { required },
            program_id: {},
            is_active: {}
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            academicCalendar: {
                academic_year_id: null,
                title: '',
                program_id: null,
                is_active: true
            },
            academicYears: [],
            programs: [],
        };
    },
    methods: {
        showModal(id = null) {
            if (id) {
                this.edit = true
                this.getAcademicCalendar(id)
            }else{
                this.resetForm();
                this.edit=false;
            }
            this.dialog = true;
            this.getAcademicYears()
            this.getPrograms()
        },
        hideModal() {
            this.dialog = false;
        },
        getAcademicCalendar(id) {
            academicCalendar
            .show(id)
            .then(response => {
                this.academicCalendar = response.data.academicCalendar;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.academicCalendar.$touch();
            if (this.$v.academicCalendar.$error) {
                setTimeout(() => {
                    this.$v.academicCalendar.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateAcademicCalendar();
                } else {
                    this.createAcademicCalendar(this.academicCalendar);
                }
            }
        },
        updateAcademicCalendar() {
            this.isBusy = true;
            academicCalendar
            .update(this.academicCalendar.id, this.academicCalendar)
            .then(response => {
                this.isBusy = false;
                if(response.data.status == 'duplicate_record'){
                    this.$snotify.error('You have already created academic calendar for this year and program.');
                    return false;
                }else{
                    this.resetForm();
                    this.$emit('refresh');
                    this.hideModal();
                    this.$snotify.success("Information added");
                }
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },

        createAcademicCalendar() {
            this.isBusy = true;
            academicCalendar
            .store(this.academicCalendar)
            .then((response) => {
                this.isBusy = false;
                if(response.data.status == 'duplicate_record'){
                    this.$snotify.error('You have already created academic calendar for this year and program.');
                    return false;
                }else{
                    this.resetForm();
                    this.$emit('refresh');
                    this.hideModal();
                    this.$snotify.success("Information added");
                }
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.academicCalendar.$reset();
            this.academicCalendar = {
                academic_year_id: null,
                title: '',
                program_id: null,
                is_active: true
            };
        },
        getAcademicYears(){
            academicYear
            .getIncompletedAcademicYears()
            .then((res) => {
                this.academicYears = res.data;
            })
            .catch((err) => {

            });
        },
        getPrograms(){
            program
            .all()
            .then((res) => {
                this.programs = res.data;
            })
            .catch((err) => {

            });
        },
    }
}
</script>
